<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          :items="['Not Complete', 'Complete', 'All']"
          label="Filter"
          v-model="filter"
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="rows"
      :loading="loading"
      :items-per-page="10"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.requested_count="{ item }">
        {{ parseFloat(item.requested_count).toLocaleString() }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.start_date="{ item }">
        <span style="white-space: nowrap" v-html="item.start_date"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.target_date="{ item }">
        <span style="white-space: nowrap" v-html="item.target_date"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.mailing_address="{ item }">
        <span v-html="item.mailing_address"></span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.business_address="{ item }">
        <span v-html="item.business_address"></span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.book_tracking_num="{ item }">
        <!-- Input Box for number -->
        <v-text-field
          v-model="item.book_tracking_num"
          @blur="updateBookTrackingNum(item, $event.target.value)"
          label="Book Tracking Number"
        ></v-text-field>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.accudata_order_id="{ item }">
        <v-icon v-if="item.accudata_order_id" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.osi_transfer_id="{ item }">
        <a
          :href="
            'https://dashboard.stripe.com/payments/' + item.osi_transfer_id
          "
          target="_blank"
        >
          Open
        </a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.sent="{ item }">
        <a
          :href="
            'https://api-v3.annuitycheck.com/seminar/booklet/complete/' +
            item.campaign_id
          "
          v-if="!item.book_tracking_num == ''"
          target="_blank"
        >
          Complete
        </a>
        <span
          v-else
          title="Please fill out tracking number before marking complete"
        >
          Complete
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "Home",

  async mounted() {
    this.raw_rows = await this.get_data();
    console.log(this.raw_rows);
    this.loading = false;
  },
  methods: {
    async updateBookTrackingNum(item, value) {
      await axios.post(
        `https://api-v3.annuitycheck.com/seminar/booklet/tracking/${item.campaign_id}`,
        {
          tracking: value,
        }
      );
    },
    async get_data() {
      let d = (
        await axios.get("https://api-v3.annuitycheck.com/hasura/book_manager")
      ).data.result;

      for (let index = 0; index < d.length; index++) {
        const element = d[index];
        d[index].agentName = element.first_name + " " + element.last_name;
        d[index].mailing_address = `${element.mailing_street || ""}<br>${
          element.mailing_city || ""
        } ${element.mailing_state || ""} ${element.mailing_zip || ""}`;
        d[index].business_address = `${element.business_street || ""}<br>${
          element.business_city || ""
        } ${element.business_state || ""} ${element.business_zip || ""}`;
        d[index].target_date = dayjs(element.start_date)
          .add(10, "days")
          .format("YYYY-MM-DD");
      }

      return d;
    },
  },

  computed: {
    rows() {
      if (this.filter === "Not Complete") {
        return this.raw_rows.filter((r) => {
          return r.book_status === "pending" || r.book_status === "processing";
        });
      } else if (this.filter === "Complete") {
        return this.raw_rows.filter((r) => {
          return r.book_status === "complete";
        });
      } else {
        return this.raw_rows;
      }
    },
  },

  data() {
    return {
      headers: [
        { text: "Purchase ID", value: "campaign_id" },
        { text: "Purchase Date", value: "start_date" },
        { text: "Target", value: "target_date" },
        { text: "Agent", value: "agentName" },
        { text: "Email", value: "email" },
        { text: "Shipping Address", value: "mailing_address" },
        { text: "Business Address", value: "business_address" },
        { text: "Phone", value: "phone" },
        { text: "Disclaimer", value: "book_disclaimer" },
        { text: "Book Cnt", value: "book_count" },
        { text: "Package Status", value: "book_status" },
        { text: "Package Tracking", value: "book_tracking_num" },
        { text: "Mark Sent", value: "sent", sortable: false },
      ],
      loading: true,
      sortBy: "events",
      sortDesc: true,
      filter: "Not Complete",

      raw_rows: [],
    };
  },
};
</script>
